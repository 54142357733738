/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Paper, Box, Typography, Container, Button,
} from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import Header from '../../components/common/Header';
import DetailsView from '../../components/Settings/DetailsView';
import ContentView from '../../components/Settings/ContentView';
import ExperienceView from '../../components/Settings/ExperienceView';
import AdditionalView from '../../components/Settings/AdditionalView';
import ChannelsView from '../../components/Settings/ChannelsView';
import InfiniteLoader from '../../components/common/InfiniteLoader';
import CustomModal from '../../components/common/CustomModal';
import useNotifications from '../../hooks/useNotifications';
import Select from '../../components/common/SelectMui';
import {
  DARK_GREY,
  SWITCH_GREY,
  MODERATE_DARK_GREY,
  MCKINSEY_BLUE,
  CHIP_LABEL_BLUE,
  WHITE,
  ERROR_RED,
  MODERATE_LIGHT_GREY,
  RED_DARK,
  BLACK,
} from '../../stylesheets/colors';
import { getLearningPlanSettings, updateLearningPlanSettings, updateLearningPlanAccess } from './api';
import { fetchActiveLanguages } from '../common/apis';
import {
  DEFAULT,
  ROUTE_REPORT,
  SETTINGS,
  ROLE_PROD,
  STAGE_LAUNCHED,
  STAGE_DELETED,
  ADMIN_GROUP,
  LP_USER_ACCESS_OPTIONS as options,
} from '../../constants';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import ConfirmationIcon from '../../assets/icons/refreshConfirmation.png';

const SideLinks = [
  { id: 1, label: 'Details & Status' },
  { id: 2, label: 'Participant Experience' },
  { id: 3, label: 'Content' },
  { id: 5, label: 'Channels' },
  { id: 4, label: 'Additional Information' },
];

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  alertSnackBar: {
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    minHeight: '600px',
  },
  sidelinksWrapper: {
    flex: 1,
    borderRight: `1px solid ${SWITCH_GREY}`,
    '& .side-links': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '2rem 4rem 1.6rem',
      fontSize: '14px',
      borderBottom: `1px solid ${SWITCH_GREY}`,
      fontWeight: '400',
      '&.active': {
        color: MCKINSEY_BLUE,
        fontWeight: 500,
      },
      '&.no-pointer': {
        pointerEvents: 'none',
        opacity: 0.7,
      },
      '& span': {
        cursor: 'pointer',
      },
    },
  },
  formWrapper: {
    flex: 4,
    padding: '0 2rem',
    '& .w100': {
      width: '100%',
    },
    '& button.danger': {
      backgroundColor: RED_DARK,
      color: WHITE,
    },
    '& .dangertext': {
      color: RED_DARK,
    },
    '& .form-header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${SWITCH_GREY}`,
      padding: '2rem 0',
      '& .form-header-title': {
        color: DARK_GREY,
        fontSize: '1.5rem',
        fontWeight: '500',
      },
      '& button': {
        marginLeft: '2rem',
      },
    },
    '& .form-content': {
      backgroundColor: 'transparent',
      '& .form-group': {
        borderBottom: `1px solid ${SWITCH_GREY}`,
        '&:last-child': {
          borderBottom: 'none',
        },
        '& .form-column': {
          flexDirection: 'column',
        },
        '&.flex': {
          display: 'flex',
          '& .form-cell': {
            marginBottom: '2rem',
          },
        },
        '& .form-row': {
          display: 'flex',
          padding: '2rem 0',
          flex: 1,
          '&.program': {
            '& .form-cell': {
              flex: 0.5,
            },
          },
          '& .form-cell': {
            flex: 1,
            '& .form-field': {
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              ' & .fld-name': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.125rem',
                paddingBottom: '5px',
                fontWeight: 500,
                color: MODERATE_DARK_GREY,
                '& svg': {
                  marginLeft: '0.625rem',
                },
              },
              ' & .fld-helper': {
                fontSize: '1rem',
                color: MODERATE_DARK_GREY,
                marginLeft: '0.625rem',
              },
              ' & .fld-value': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '1.25rem',
                color: MODERATE_DARK_GREY,
                marginTop: '2px',
                paddingRight: '6.25rem',
                wordBreak: 'break-word',
                fontWeight: 'normal',
                '&.mt10': {
                  marginTop: '1rem',
                },
                '& > span': {
                  display: 'flex',
                },
                '& p': {
                  margin: 0,
                },
                '& img': {
                  maxWidth: '100%',
                },
                // '& .extrainfo': {
                //   paddingLeft: '.5rem',
                // },
                '& .readmore': {
                  fontWeight: '500',
                  color: MCKINSEY_BLUE,
                  textDecoration: 'underline',
                  textAlign: 'right',
                },
                '& .linkify-value': {
                  display: 'flex',
                  flexDirection: 'column',
                },
                '& .text-clip': {
                  display: 'flex',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  justifyContent: 'flex-start',
                  marginBottom: '.675rem',
                  flexDirection: 'column',
                },
                '& .align-text': {
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .popup-label': {
                  color: MCKINSEY_BLUE,
                  fontWeight: 500,
                  textDecoration: 'underline',
                  marginLeft: '1rem',
                  cursor: 'pointer',
                },
                '&.fld-type_of_program': {
                  justifyContent: 'flex-start',
                  gap: '5px',
                },
                '&.fld-chip.multiple': {
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  padding: '0 1rem 0rem 0px',
                },
                '&.fld-chip2.multiple': {
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  padding: '0 1rem 0rem 0px',
                },
                '&.fld-lineage': {
                  justifyContent: 'flex-start !important',
                  alignItems: 'flex-end !important',
                  '& svg': {
                    color: MCKINSEY_BLUE,
                    marginLeft: '.75rem',
                  },
                },
                '&.fld-chip span': {
                  backgroundColor: WHITE,
                  borderRadius: '1px',
                  padding: '0.5rem 0.5rem 0.5rem 0',
                  marginBottom: '1rem',
                  color: BLACK,
                  fontWeight: 400,
                  '&.active': {
                    backgroundColor: CHIP_LABEL_BLUE,
                    color: BLACK,
                  },
                  '&.pointer': {
                    cursor: 'pointer',
                  },
                },
                '&.fld-chip2 span': {
                  backgroundColor: MODERATE_LIGHT_GREY,
                  borderRadius: '4px',
                  padding: '0.5rem 1.25rem',
                  marginBottom: '1rem',
                  marginRight: '1rem',
                  color: MODERATE_DARK_GREY,
                  fontWeight: 400,
                  '&.active': {
                    backgroundColor: CHIP_LABEL_BLUE,
                    color: WHITE,
                  },
                  '&.pointer': {
                    cursor: 'pointer',
                  },
                },
              },
              '& .fld-switchinput': {
                paddingRight: 0,
              },
              '& .fld-input': {
                paddingRight: '2rem',
              },
              '& .align-input': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .fld-width900': {
                width: '900px',
              },
              '& .fld-icons': {
                position: 'absolute',
                top: ({ isEdit }) => (isEdit ? '3.625rem' : '1.75rem'),
                right: '4.75rem',
              },
            },
          },
          '& .form-column': {
            flexDirection: 'column',
          },
        },
        '& .form-row + .form-row': {
          paddingTop: '0',
        },
      },
    },
    '& .separator': {
      padding: '0 1rem',
    },
    '& .error': {
      color: ERROR_RED,
      fontWeight: 400,
    },
    '& svg': {
      cursor: 'pointer',
    },
    '& .ck-editor__main': {
      wordBreak: 'break-word',
      '& textarea': {
        overflow: 'auto',
        height: '300px',
      },
      '& .ck-source-editing-area, & .ck-content': {
        height: '300px',
        maxWidth: '900px',
      },
    },
  },
  clientModal: {
    padding: '3rem',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    '& .modal-title': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    '& .modal-message': {
      fontSize: '1.5rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .table': {
      marginTop: '1rem',
      '& .table-row': {
        display: 'flex',
        textAlign: 'center',
        fontSize: '1.25rem',
        padding: '.875rem',
        borderBottom: `1px solid ${SWITCH_GREY}`,
        color: MODERATE_DARK_GREY,
        '&.table-head': {
          fontWeight: 400,
          backgroundColor: MODERATE_LIGHT_GREY,
        },
      },
      '& .table-col': {
        flex: 1,
        wordBreak: 'break-all',
      },
    },
  },
  academyGoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  horizontalAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  userAccessWrapper: {
    width: '12%',
  },
  modalContentwrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  modalStyle: {
    minWidth: '70rem',
  },
  modalHeight: {
    minHeight: '35rem',
    height: 'auto',
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
});

const Views = {
  1: DetailsView,
  2: ExperienceView,
  3: ContentView,
  4: AdditionalView,
  5: ChannelsView,
};

const SettingsContainer = ({
  location,
  history,
  match,
  experienceTypeOptions,
  extendedEnterpriseTypeOptions,
  isAdmin,
  getValueFromConfig,
  qs,
  userRole,
  onProgramMetadataRefresh,
}) => {
  const { push } = history;
  const { programId, programSubType } = match.params;

  const [view, setView] = useState(1);
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lpSettings, setLpSettings] = useState({});
  const [stages, setStages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [disabledUserRole, setDisabledUserRole] = useState(false);
  const classes = useStyles({ isEdit });
  const [extendedPlatformUrls, setExtendedPlatformUrls] = useState([]);
  const [userAccessConfirmationModal, setUserAccessConfirmationModal] = useState(false);
  const [tempUserAccessvalue, setTempUserAccessvalue] = useState(null);
  const [userAccessLoading, setUserAccessLoading] = useState(false);

  const { notifySuccess, notifyError } = useNotifications();

  useEffect(() => {
    if (qs) {
      const params = new URLSearchParams(qs);
      const tab = +params.get('tab');
      if (tab >= 1 && tab <= 5) {
        setView(tab);
        if (!params.get('mode')) {
          setEdit(true);
        }
      }
    }
  }, [qs]);

  useEffect(() => {
    const queryString = programSubType.split('?')[1];
    const searchFrom = location.search || queryString || '';
    if (searchFrom.includes('tab=')) {
      push(`/${ROUTE_REPORT}/${programId}/${SETTINGS}`);
    }
  }, [view, programId, programSubType, location.search, push, userRole]);

  useEffect(() => {
    const platformUrlsOptions = [
      {
        name: DEFAULT, code: DEFAULT, url: lpSettings?.docebo_url, id: 999,
      },
      ...extendedEnterpriseTypeOptions,
    ].map((el) => ({ ...el, label: `${el.name} (${el.code} - ${el.url})`, value: el.code }));
    setExtendedPlatformUrls(platformUrlsOptions);
  }, [lpSettings, extendedEnterpriseTypeOptions]);

  const getLearningPlanConfig = async (prgId) => {
    setIsLoading(true);
    getLearningPlanSettings(prgId).then(({ data }) => {
      let language = '';
      let tags = '';
      if (data?.metadata?.metadata && data.metadata.metadata.length > 0) {
        language = data.metadata.metadata[0].value;
      }
      if (data?.tags) {
        tags = data.tags.split(',').map((s) => s.trim()).join(',');
      }
      setLpSettings({
        ...data,
        language,
        tags,
        stages_list: undefined,
      });
      if (data?.stages_list) {
        const stageOptions = data.stages_list;
        setStages(stageOptions);
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const getLanguages = async () => {
    fetchActiveLanguages().then(({ data }) => {
      const activeLanguages = Object.values(data.languages).map((lang) => ({
        label: lang.description,
        value: lang.description,
        code: lang.code,
        shortCode: lang.browsercode,
      }));
      setLanguages(activeLanguages);
    });
  };

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    if (programId) {
      getLearningPlanConfig(programId);
    }
  }, [programId]);

  useEffect(() => {
    let disableUserStatus = false;
    if (ADMIN_GROUP.includes(userRole)) {
      disableUserStatus = false;
    } else if (userRole === ROLE_PROD) {
      const allowedStages = stages.filter((el) => ![STAGE_LAUNCHED, STAGE_DELETED].includes(el));
      disableUserStatus = !allowedStages.includes(lpSettings.stage);
    } else {
      disableUserStatus = true;
    }
    setDisabledUserRole(disableUserStatus);
  }, [stages, userRole, lpSettings.stage]);

  const onSubmit = (values) => {
    setIsLoading(true);

    const payload = {
      docebo_lp_id: values.docebo_lp_id,
    };

    if (view === 1) {
      //   detials and status
      payload.dd_analyst = values.dd_analyst;
      payload.stage = values.stage;
      payload.cp_poc = values.cp_poc;
      payload.course_delivery_analyst = values.course_delivery_analyst;
      payload.is_locked = values.is_locked;
      payload.offering_owner = values.offering_owner;
      payload.is_academy_go = values.is_academy_go;
      payload.is_gsr = values.is_gsr;
      payload.is_skill = values.is_skill;
      payload.additional_fields = values.additional_fields;
    }

    if (view === 2) {
      //   participant experience
      payload.experience = values.experience;
      payload.platform_url = values.platform_url;
      payload.docebo_learning_plan_url = (values.docebo_learning_plan_url || '').trim();
      payload.language = values.language;
      payload.selectedLanguage = values.selectedLanguage;
    }

    if (view === 3) {
      //   content
      payload.name = values.name.trim();
      payload.description = values.description;
    }

    if (view === 4) {
      //   additional information
      payload.tags = values?.tags;
      payload.additional_resources = values?.additional_resources?.trim();
    }

    updateLearningPlanSettings(programId, payload)
      .then(({ data }) => {
        setLpSettings(values);
        setIsLoading(false);
        setEdit(false);
        notifySuccess(data.message);
        getValueFromConfig(values.stage, values.experience, values.platform_url);
        onProgramMetadataRefresh(programId);
      })
      .catch((err) => {
        setIsLoading(false);
        setEdit(false);
        if (err.response.status === 400) {
          notifyError(err.response.data.message);
        } else {
          notifyError(err.message);
        }
      });
  };

  const onCancel = () => {
    localStorage.setItem('currentStage', lpSettings.stage);
    setEdit(false);
  };

  const clientList = lpSettings?.client_list || [];
  if (!clientList.length) {
    const platform_url = lpSettings?.platform_url
      ? extendedPlatformUrls.find((el) => el.code === lpSettings.platform_url)?.url
      : '';
    clientList.push({ client_id: lpSettings.platform_url, client_name: platform_url });
  }

  const View = Views[view] || (() => null);

  const updateUserAccessofLP = async () => {
    try {
      const { name, description, docebo_lp_id } = lpSettings;
      const resp = await updateLearningPlanAccess(docebo_lp_id, { title: name, description, is_locked: tempUserAccessvalue });
      if (resp.data.success === true) {
        notifySuccess(resp.data.message);
        setLpSettings({ ...lpSettings, is_locked: resp.data.is_locked });
      }
    } catch (err) {
      if (err.response.status === 400) {
        notifyError(err.response.data.message);
      } else {
        notifyError(err.message);
      }
    } finally {
      setUserAccessLoading(false);
      setUserAccessConfirmationModal(false);
      setTempUserAccessvalue(null);
    }
  };

  const onConfirmUserAccess = () => {
    setUserAccessLoading(true);
    updateUserAccessofLP();
  };

  const onCancelAccessvalue = () => {
    setUserAccessConfirmationModal(false);
    setTempUserAccessvalue(null);
  };

  const handleChangeUserAccess = (obj) => {
    setUserAccessConfirmationModal(true);
    setTempUserAccessvalue(obj.value);
  };

  return (
    <Paper className={classes.wrapper}>
      <Header
        heading="Learning Plan Settings"
        subHeading="Edit and manage Learning plan settings"
        childrenWrapper={classes.userAccessWrapper}
      >
        <Box style={{ width: '100%' }}>
          <Typography variant="body1">
            LP User Access
          </Typography>
          <Select
            name="user-access"
            defaultValue={options.find((el) => el.value === lpSettings?.is_locked)}
            options={options}
            onChange={(v) => handleChangeUserAccess(v)}
            isMulti={false}
          />
        </Box>
      </Header>
      <div className={classes.content}>
        <div className={classes.sidelinksWrapper}>
          {SideLinks.map((link) => (
            <div
              key={`link_${link.id}`}
              className={`side-links ${link.id === view ? 'active' : 'regular'} 
                ${isEdit ? 'no-pointer' : ''}`}
            >
              <span tabIndex={0} role="button" onClick={() => setView(link.id)} onKeyDown={() => setView(link.id)}>
                {link.label}
              </span>
              {link.id === view && <ArrowRightAlt />}
            </div>
          ))}
        </div>
        <div className={classes.formWrapper}>
          <View
            settings={lpSettings}
            isEdit={isEdit}
            experienceTypeOptions={experienceTypeOptions}
            extendedEnterpriseTypeOptions={extendedPlatformUrls}
            onSubmit={onSubmit}
            setEdit={setEdit}
            languages={languages}
            stages={stages}
            onEdit={() => setEdit(true)}
            setOpenModal={setOpenModal}
            isAdmin={isAdmin}
            onCancel={onCancel}
            push={push}
            disableUserStatus={disabledUserRole}
          />
        </div>
      </div>

      {isLoading && <InfiniteLoader withBg />}

      <CustomModal open={openModal} onClose={() => setOpenModal(false)} breakpoint="sm">
        <div className={classes.clientModal}>
          {clientList.length === 0 && (
            <span className="modal-message">No client found for this Learning Plan</span>
          )}
          {clientList.length > 0 && (
            <>
              <span className="modal-title">Client List</span>
              <div className="table">
                <div className="table-row table-head">
                  <div className="table-col">Client</div>
                  <div className="table-col">Client ID</div>
                </div>
                {clientList.map((el) => (
                  <div key={`client_${el.client_id}`} className="table-row">
                    <div className="table-col">{el.client_name}</div>
                    <div className="table-col">{el.client_id}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </CustomModal>
      <ConfirmationModal
        open={userAccessConfirmationModal}
        isLoading={userAccessLoading}
        onClose={() => setUserAccessConfirmationModal(false)}
        modalStyle={classes.modalStyle}
        minHeightClass={classes.modalHeight}
        content={(
          <Container className={classes.modalContentwrapper}>
            <img src={ConfirmationIcon} alt="confirmation-icon" />
            <Typography variant="h1" component="p" className={classes.subHeading}>{`${tempUserAccessvalue ? 'Lock' : 'Publish'} Learning Plan`}</Typography>
            <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
              {`This will mark the current Learning Plan to be ${tempUserAccessvalue ? 'locked' : 'published'} to access all the materials on Docebo ${tempUserAccessvalue ? 'will restrict' : ''}.`}
            </Typography>
            <Typography variant="subtitle2" component="p" className={`${classes.infoContentText} ${classes.topPadding}`}>
              Click on &ldquo;Confirm&rdquo; to continue
            </Typography>
          </Container>
        )}
        actions={(
          <>
            <Button color="primary" variant="outlined" onClick={onCancelAccessvalue}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onConfirmUserAccess}>
              Confirm
            </Button>
          </>
        )}
      />
    </Paper>
  );
};

SettingsContainer.defaultProps = {
  isAdmin: false,
  qs: '',
};

SettingsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  experienceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  extendedEnterpriseTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isAdmin: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  getValueFromConfig: PropTypes.func.isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
  qs: PropTypes.string,
};

export default SettingsContainer;
