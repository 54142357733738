import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DARK_GREY, MCKINSEY_BLUE, WHITE } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  loadMoreButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  loadMoreButton: {
    fontSize: '1.125rem',
    padding: '0.5rem 1rem',
    color: ({ primaryColor }) => primaryColor,
    backgroundColor: WHITE,
    border: ({ primaryColor }) => `1px solid ${primaryColor}`,
    width: '14rem',
    textAlign: 'center',
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px auto 0',
    // width: '3rem',
    width: '18%',
  },
  progressBar: {
    margin: '1rem 0',
  },
  progressCount: {
    margin: '0 auto',
    textAlign: 'center',
  },
}));

const LoadMoreComponent = ({
  isLoading, hasMore, onLoadMore, loadMoreText, itemsShown, totalItems, btnRef, wrapperClass,
}) => {
  const primaryColor = isLoading ? DARK_GREY : MCKINSEY_BLUE;
  const classes = useStyles({ primaryColor });
  const [progress, setProgress] = useState(0);

  const percentage = (partialValue, totalValue) => (100 * partialValue) / totalValue;

  useEffect(() => {
    const percent = percentage(itemsShown, totalItems);
    setProgress(percent);
  }, [itemsShown, totalItems]);

  const text = isLoading ? 'Loading...' : `Load more ${loadMoreText}`;

  const boldText = (val) => <Typography variant="body1" component="span" className={classes.bold}>{val}</Typography>;

  const buttonDom = (
    <Box sx={{ width: '18%' }} className={`${classes.progressWrapper} ${wrapperClass}`} ref={btnRef}>
      {!isLoading && (
        <Typography variant="body1" className={classes.progressCount}>
          Showing
          {' '}
          {boldText(itemsShown)}
          {' '}
          of
          {' '}
          {boldText(totalItems)}
          {' '}
          {loadMoreText}
        </Typography>
      )}
      <LinearProgress variant="determinate" color="primary" value={progress} className={classes.progressBar} />
      <div className={classes.loadMoreButtonWrapper}>
        {(isLoading || hasMore) && (
          <button
            type="button"
            className={classes.loadMoreButton}
            onClick={onLoadMore}
            disabled={isLoading}
          >
            {text}
          </button>
        )}
      </div>
    </Box>
  );

  return buttonDom;
};

LoadMoreComponent.defaultProps = {
  btnRef: () => {},
  wrapperClass: '',
};

LoadMoreComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loadMoreText: PropTypes.string.isRequired,
  itemsShown: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  btnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  wrapperClass: PropTypes.string,
};

export default LoadMoreComponent;
