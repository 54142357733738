import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';

import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Select from '../../components/common/CustomSelect';
// eslint-disable-next-line import/no-cycle
import Timeline from '../../components/ActivityLogs/Timeline';
import Instruction from '../../components/ActivityLogs/Instruction';
import { MODERATE_LIGHT_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';
import {
  getEnrollmentTransactions,
  getPlatformTransactions,
} from './api';
import SelectGroupedCollapsible from '../../components/common/SelectGroupedCollapsible';
import LoadMoreComponent from '../../components/common/LoadMore';
import {
  BULK_EDIT_COMPONENT_METADATA,
  INDIVIDUAL_SCORM_REFRESH,
  MULTIPLE_SCORM_REFRESH,
  AUTO_ENROLLMENT,
  BULK_UNENROLLMENT,
  WAVE_MANAGEMENT,
  BULK_UPDATE_ACCOUNTS,
  TRANSFER_COHORT_USERS,
  MODIFY_GRADE,
  UPLOAD_GRADE,
  SYNC_SESSION_ENROLLMENTS,
  SINGLE_SESSION_SYNC,
  WEBINAR_MANAGEMENT,
  OVERVIEW,
  CONTENT_MANAGEMENT,
  WORKSHOP_MANAGEMENT,
  TEST_USERS_CREATION,
  ASSIGNMENT_DOWNLOAD,
  GROUPWORK_GROUPS,
  REPLICATION,
  EDIT_PROGRAM,
  METADATA_MANAGEMENT,
  SETTINGS,
  COMPONENTMETADATA,
  COHORT_USER_METADATA, GROUP_ENROLLMENT, MARK_ALL_USERS_COMPLETE, PLATFORM_USER,
  BULK_EDIT_USER_PASSWORD,
  CLONE_COURSE,
  IMPORT_COURSE,
  BULK_UPDATE_COMPONENT_ACCESS,
  REORDER_COMPONENTS,
  REORDER_TRAINING_MATERIALS,
  ADD_TRAINING_MATERIAL,
} from '../../constants';

const useStyles = makeStyles({
  globalLogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    overflow: 'auto',
    minHeight: '65vh',
  },
  logHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    padding: '2rem 1rem',
  },
  headerLinks: {
    display: 'flex',
    flexDirection: 'row',
  },
  verticalDivider: {
    '&.MuiDivider-root': {
      borderColor: MCKINSEY_BLUE,
      height: '50%',
      alignSelf: 'center',
    },
  },
  filterSection: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filter: {
    maxWidth: '15rem',
  },
  progressWrapper: {
    width: '40%',
    padding: '1.5rem 0',
  },
});

const groupedOptions = [
  { value: 'all', label: 'All' },
  {
    label: 'User Management',
    options: [
      { label: 'User Enrollment', value: AUTO_ENROLLMENT },
      { label: 'User Un-enrollment', value: BULK_UNENROLLMENT },
      { label: 'User Activation/Deactivation', value: BULK_UPDATE_ACCOUNTS },
      { label: 'Transfer Users (ECs)', value: TRANSFER_COHORT_USERS },
      { label: 'EC User Metadata', value: COHORT_USER_METADATA },
      { label: 'Bulk Password Update', value: BULK_EDIT_USER_PASSWORD },
    ],
  },
  {
    label: 'Assignment Management',
    options: [
      { label: 'Evaluate Assignments', value: UPLOAD_GRADE },
      { label: 'Modify Grades', value: MODIFY_GRADE },
    ],
  },
  {
    label: 'Content Management',
    options: [
      { label: 'Individual SCORM Refresh', value: INDIVIDUAL_SCORM_REFRESH },
      { label: 'Multiple SCORM Refresh', value: MULTIPLE_SCORM_REFRESH },
      { label: 'Copy Component Shell', value: CLONE_COURSE },
      { label: 'Import a Component Copy', value: IMPORT_COURSE },
      { label: 'Bulk Update Component Access', value: BULK_UPDATE_COMPONENT_ACCESS },
      { label: 'Re-ordering components', value: REORDER_COMPONENTS },
      { label: 'Reorder Training Materials', value: REORDER_TRAINING_MATERIALS },
      { label: 'Add Training Material', value: ADD_TRAINING_MATERIAL },
    ],
  },
  {
    label: 'Workshop Management',
    options: [
      { label: 'Enrollment Sync', value: SYNC_SESSION_ENROLLMENTS },
      { label: 'Session Enrollment Sync', value: SINGLE_SESSION_SYNC },
      { label: 'Mark User As Complete', value: WEBINAR_MANAGEMENT },
      { label: 'Mark All Users Complete', value: MARK_ALL_USERS_COMPLETE },
    ],
  },
  {
    label: 'Group Management',
    options: [
      { label: 'Group Enrollment', value: GROUP_ENROLLMENT },
    ],
  },
];

const insTructionOptions = [
  { label: 'Overview', value: OVERVIEW },
  {
    label: 'User Management',
    options: [
      { label: 'User Enrollment', value: AUTO_ENROLLMENT },
      { label: 'User Un-enrollment', value: BULK_UNENROLLMENT },
      { label: 'Activate / Deactivate Users', value: BULK_UPDATE_ACCOUNTS },
      { label: 'Enrollment Cohort', value: WAVE_MANAGEMENT },
    ],
  },
  { label: 'Content Management', value: CONTENT_MANAGEMENT },
  { label: 'Workshop Management', value: WORKSHOP_MANAGEMENT },
  { label: 'Test User Management', value: TEST_USERS_CREATION },
  {
    label: 'Assignment Management',
    options: [
      { label: 'Assignment Download', value: ASSIGNMENT_DOWNLOAD },
      { label: 'Evaluation', value: UPLOAD_GRADE },
      { label: 'Group Management', value: GROUPWORK_GROUPS },
    ],
  },
  {
    label: 'Advanced Configuration',
    options: [
      { label: 'Replication', value: REPLICATION },
      { label: 'Modify Config ID', value: EDIT_PROGRAM },
      { label: 'Metadata Management', value: METADATA_MANAGEMENT },
    ],
  },
  { label: 'Settings', value: SETTINGS },
  { label: 'Component Metadata', value: COMPONENTMETADATA },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'COMPLETED_WITH_ERRORS', label: 'Completed with Errors' },
];

const ActivityLog = ({
  selectedMenu, programId, programMetadata, programSubType,
}) => {
  const [timeline, setTimeline] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({ value: 'all', label: 'All' });
  const [selectedInstruction, setSelectedInstruction] = useState({ value: 'overview', label: 'Overview' });
  const [selectedStatus, setSelectedStatus] = useState({ value: 'all', label: 'All' });
  const [page, setPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pageSize = 10;

  const platformType = {
    componentMetadata: ['bulk_edit_component_metadata'],
    users: ['edit_user_detail', 'user_deletion_flag', 'user_deletion_unflag'],
  };

  let configId = '';
  if (programId) {
    configId = get(programMetadata, 'config_id', '');
  } else if (programSubType === 'componentMetadata') {
    configId = BULK_EDIT_COMPONENT_METADATA;
  } else {
    configId = PLATFORM_USER;
  }

  const classes = useStyles();

  const selectFeatureRef = useRef(null);
  const selectStatusRef = useRef(null);
  const selectInstructionRef = useRef(null);

  const isProcessQueue = selectedMenu.key === 'processQueue';
  const isInstruction = selectedMenu.key === 'instruction';

  const findFeature = useCallback((programtype, program) => {
    const result = _flatMap(program, (item) => {
      if (item.options) {
        return item.options;
      }
      return item;
    });
    const selectedProgram = _find(result, (item) => {
      if (item.value && item.value === programtype) {
        return item;
      }
      return false;
    });
    return selectedProgram || selectedInstruction;
  }, [selectedInstruction]);

  useEffect(() => {
    const feature = findFeature(programSubType, insTructionOptions);
    if (selectInstructionRef.current) {
      selectInstructionRef.current.select.setValue(feature);
    }
    setSelectedInstruction(feature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = useCallback(async (pageValue, processQueue, logs) => {
    setIsLoading(true);
    const resp = await getEnrollmentTransactions(programId, selectedFeature.value,
      selectedStatus.value, processQueue, pageValue, pageSize);
    const newLogs = resp.data.data;
    setTimeline(logs.concat(newLogs));
    setTotalLogs(resp.data.total_rows);
    setHasMore(resp.data.has_more);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, selectedFeature.value, selectedStatus.value]);

  const fetchPlatformLogs = useCallback(async (pageValue, processQueue, logs) => {
    setIsLoading(true);
    const resp = await getPlatformTransactions(platformType[programSubType],
      selectedStatus.value, processQueue, pageValue, pageSize);
    const newLogs = resp.data.data;
    setTimeline(logs.concat(newLogs));
    setTotalLogs(resp.data.total_rows);
    setHasMore(resp.data.has_more);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus.value]);

  useEffect(() => {
    setPage(1);
    setTimeline([]);
    if (programId) {
      fetchLogs(1, isProcessQueue, []);
    } else {
      fetchPlatformLogs(1, isProcessQueue, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessQueue, selectedStatus, selectedFeature]);

  const viewAllLogs = () => {
    setSelectedFeature({ value: 'all', label: 'All' });
    if (selectFeatureRef.current) selectFeatureRef.current.select.setValue({ value: 'all', label: 'All' });
    if (!isProcessQueue) {
      setSelectedStatus({ value: 'all', label: 'All' });
      if (selectStatusRef.current) selectStatusRef.current.select.setValue({ value: 'all', label: 'All' });
    }
  };

  const handleRefresh = () => {
    setPage(1);
    setTimeline([]);
    if (programId) {
      fetchLogs(page, isProcessQueue, []);
    } else {
      fetchPlatformLogs(page, isProcessQueue, []);
    }
  };

  const onLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    if (programId) {
      fetchLogs(newPage, isProcessQueue, timeline);
    } else {
      fetchPlatformLogs(newPage, isProcessQueue, timeline);
    }
  };

  return (
    <Box sx={{ width: 380, borderColor: 'divider' }} className={classes.globalLogWrapper}>
      <Box className={classes.logHeader}>
        <Typography variant="subtitle1">{selectedMenu.title}</Typography>
        {!isInstruction && (
          <Box className={classes.headerLinks}>
            <Button
              variant="text"
              color="primary"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={viewAllLogs}
            >
              View all Logs
            </Button>
            <Divider orientation="vertical" flexItem textAlign="center" classes={{ root: classes.verticalDivider }} />
            <Button
              variant="text"
              color="primary"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </Box>
        )}
      </Box>
      <Box className={classes.logContainer}>
        <Box className={classes.filterSection}>
          {![BULK_EDIT_COMPONENT_METADATA, PLATFORM_USER].includes(configId) && !isInstruction
            ? (
              <SelectGroupedCollapsible
                selectRef={selectFeatureRef}
                name="feature"
                label="Filter by Feature"
                defaultValue={selectedFeature}
                className={classes.filter}
                options={groupedOptions}
                placeholder="Select Feature..."
                onChange={(e) => setSelectedFeature(e)}
              />
            ) : null}
          {
            isInstruction && (
              <SelectGroupedCollapsible
                selectRef={selectInstructionRef}
                name="feature"
                label="Filter by Feature"
                defaultValue={selectedInstruction}
                className={classes.filter}
                options={insTructionOptions}
                placeholder="Select Feature..."
                onChange={(e) => setSelectedInstruction(e)}
              />
            )
          }
          {
            (!isProcessQueue && !isInstruction) && (
              <Select
                selectRef={selectStatusRef}
                name="status"
                defaultValue={selectedStatus}
                className={classes.filter}
                label="Filter by Status"
                placeholder="Select Status..."
                options={statusOptions}
                onChange={(e) => setSelectedStatus(e)}
              />
            )
          }
        </Box>
        {
          !isInstruction
            ? (
              <>
                <Timeline
                  timeline={timeline}
                  configId={configId}
                  programId={programId}
                />
                <LoadMoreComponent
                  totalItems={totalLogs}
                  onLoadMore={onLoadMore}
                  loadMoreText="Logs"
                  itemsShown={timeline.length}
                  hasMore={hasMore}
                  isLoading={isLoading}
                  wrapperClass={classes.progressWrapper}
                />
              </>

            )
            : <Instruction feature={selectedInstruction} />
        }
      </Box>
    </Box>
  );
};

ActivityLog.defaultProps = {
  programId: '',
  programMetadata: {},
};

ActivityLog.propTypes = {
  selectedMenu: PropTypes.shape({
    key: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  programId: PropTypes.string,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
  }),
  programSubType: PropTypes.string.isRequired,
};

export default ActivityLog;
